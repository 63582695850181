const resources = {
  no: {
    translation: {
      orderNumber: 'Ordrenummer', /* 'Ordre&shy;nummer' could have been good in case of responsive
        button size, but HTML entities are apparently not supported (in a safe and very easily
        usable way) by react i18n */
      quoteNumber: 'Tilbudsnummer', // 'Tilbuds&shy;nummer'
      totalArea: 'Totalt areal',
      numberOfProducts: 'Antall produkter',
      orderLines: 'Varelinjer',
      status: 'Status',
      meanUValue: 'Snitt u-verdi',
      uValueAccounts: 'U-verdi regnskap', // 'U&#x2011;verdi regnskap'
      trackingInfo: 'Sporingsinformasjon', // 'Sporings&shy;informasjon'
      productMeasurements: 'Produktmål',
      hide: 'Skjul',
      product: 'Produkt',
      show: 'Vis',
      quantity: 'Antall',
      reference: 'Ref.',
      drawing: 'Tegning',
      productDescription: 'Produktbeskrivelse',
      uValue: 'U-verdi',
      area: 'Areal',
      documentation: 'Dokumentasjon',
      nobbInfo: 'NOBB-informasjon',
      getReport: 'Hent rapport',
      maintenanceLong: 'Forvaltning, drift, vedlikehold (FDV)',
      complaintHeader: 'Reklamasjon',
      complaintBtn: 'Reklamasjonsskjema',
      consignmentNumber: 'Fraktbrevnummer',
      deliveryDate: 'Leveringsdato',
      tracking: 'Sporing',
      noTrackingInfo: 'Fant ingen sporingsinformasjon',
      width: 'Bredde',
      height: 'Høyde',
      showImg: 'Vis bilde',
      noImg: 'Ingen bilde',
      statusText: {
        approved: 'Godkjent',
        delivered: 'Sendt',
        empty: 'Ingen varelinjer',
        expired: 'Utløpt',
        follow_up: 'Følges opp',
        for_approval: 'Til godkjenning',
        not_ordered: 'Ikke bestilt',
        partly_delivered: 'Delvis levert',
        ready_to_order: 'Klar til bestilling',
        unknown: 'Ukjent',
      },
      error: {
        somethingWentWrong: 'Noe gikk galt',
        couldNotGetReport: 'Kunne ikke hente rapport',
        missingId: 'Mangler id',
        couldNotGetOrderInfo: 'Kunne ikke hente ordreinformasjon',
      },
      recommendedAction: {
        tryAgainLater: 'Vennligst prøv igjen senere',
      },
      natre: {
        footerLink: 'Gå til natre.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      panora: {
        footerLink: 'Gå til panora.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      uldal: {
        footerLink: 'Gå til uldal.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      lf: {
        footerLink: 'Gå til inwido.com',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
    },
  },
  en: {
    translation: {
      orderNumber: 'Order number',
      quoteNumber: 'Quote number',
      totalArea: 'Total area',
      numberOfProducts: 'Number of products',
      orderLines: 'Item lines',
      status: 'Status',
      meanUValue: 'Mean u-value',
      uValueAccounts: 'U-value accounts',
      trackingInfo: 'Tracking information',
      productMeasurements: 'Product measurements',
      hide: 'Hide',
      product: 'Produkt',
      show: 'Show',
      quantity: 'Quantity',
      reference: 'Ref.',
      drawing: 'Drawing',
      productDescription: 'Product description',
      uValue: 'U-value',
      area: 'Area',
      documentation: 'Documentation',
      nobbInfo: 'NOBB information',
      getReport: 'Hent rapport',
      maintenanceLong: 'Management, operation, maintenance (MOM)', // Todo: is this translation ok
      complaintHeader: 'Reklamasjon',
      complaintBtn: 'Reklamasjonsskjema',
      consignmentNumber: 'Consignment number',
      deliveryDate: 'Leveringsdato',
      tracking: 'Sporing',
      noTrackingInfo: 'Fant ingen sporingsinformasjon',
      width: 'Bredde',
      height: 'Høyde',
      showImg: 'Vis bilde',
      noImg: 'Ingen bilde',
      statusText: {
        approved: 'Approved',
        delivered: 'Delivered',
        empty: 'Empty',
        expired: 'Expired',
        follow_up: 'Follow up',
        for_approval: 'For approval',
        not_ordered: 'Not ordered',
        partly_delivered: 'Partly delivered',
        ready_to_order: 'Ready to order',
        unknown: 'Unknown',
      },
      error: {
        somethingWentWrong: 'Something went wrong',
        couldNotGetReport: 'Could not get report',
        missingId: 'Missing id',
        couldNotGetOrderInfo: 'Could not get order information',
      },
      recommendedAction: {
        tryAgainLater: 'Please try again later',
      },
      natre: {
        footerLink: 'Gå til natre.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      panora: {
        footerLink: 'Gå til panora.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      uldal: {
        footerLink: 'Gå til uldal.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      lf: {
        footerLink: 'Gå til inwido.com',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
    },
  },
  fr: {
    translation: {
      orderNumber: 'orderNumber en français',
      quoteNumber: 'quoteNumber en français',
      totalArea: 'totalArea en français',
      numberOfProducts: 'numberOfProducts en français',
      orderLines: 'orderLines en français',
      status: 'status en français',
      meanUValue: 'meanUValue en français',
      uValueAccounts: 'uValueAccounts en français',
      trackingInfo: 'trackingInfo en français',
      productMeasurements: 'productMeasurements en français',
      hide: 'hide en français',
      quantity: 'quantity en français',
      show: 'show en français',
      product: 'Produkt',
      reference: 'reference en français',
      drawing: 'drawing en français',
      productDescription: 'productDescription en français',
      uValue: 'uValue en français',
      area: 'area en français',
      documentation: 'documentation en français',
      nobbInfo: 'nobbInfo en français',
      maintenanceLong: 'maintenanceLong en français',
      complaintHeader: 'Reklamasjon',
      complaintBtn: 'Reklamasjonsskjema',
      consignmentNumber: 'Fraktbrevnummer',
      deliveryDate: 'Leveringsdato',
      tracking: 'Sporing',
      noTrackingInfo: 'Fant ingen sporingsinformasjon',
      width: 'Bredde',
      height: 'Høyde',
      showImg: 'Vis bilde',
      noImg: 'Ingen bilde',
      statusText: {
        approved: 'approved en français',
        delivered: 'delivered en français',
        empty: 'empty en français',
        expired: 'expired en français',
        follow_up: 'follow_up en français',
        for_approval: 'for_approval en français',
        not_ordered: 'not_ordered en français',
        partly_delivered: 'partly_delivered en français',
        ready_to_order: 'ready_to_order en français',
        unknown: 'unknown en français',
      },
      error: {
        somethingWentWrong: 'somethingWentWrong en français',
        couldNotGetReport: 'couldNotGetReport en français',
        missingId: 'missingId en français',
        couldNotGetOrderInfo: 'couldNotGetOrderInfo en français',
      },
      recommendedAction: {
        tryAgainLater: 'tryAgainLater en français',
      },
      natre: {
        footerLink: 'Gå til natre.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      panora: {
        footerLink: 'Gå til panora.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      uldal: {
        footerLink: 'Gå til uldal.no',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
      lf: {
        footerLink: 'Gå til inwido.com',
        productMeasurementText: 'Trykk knappen nedenfor for å vise informasjon om nyttige produktmål på produktene som inngår i denne leveransen.',
        fdvText: 'Trykk knappen nedenfor for å vise relevant informasjon om forvaltning, drift og vedlikehold av produktene som inngår i denne leveransen.',
        orderLineText: 'Trykk på knappen under for å vise varelinjene til denne ordren.',
        complaintText: 'Dersom det mot formodning har oppstått noe galt med denne leveransen, vennligst trykk knappen nedenfor for å sende oss informasjon om dette.',
      },
    },
  },
};

export default resources;
